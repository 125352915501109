/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import dayjs from "dayjs";
import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Input, Text } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { useDateValues } from "~/hooks";
import { isNotEmpty } from "~/tools";
import { DateRange } from "~/types";
import { getDateToMinValue } from "~/utils/dates";
import { FilterHeader } from "./FilterHeader";
import { Mode } from "./types";

type Props = {
  onRangeSelect: (range: DateRange) => void;
  selectedRange?: Partial<DateRange>;
  label?: string;
};

const isEndDateValid = (startDate: string, endDate: string | undefined) => {
  return !!(
    endDate &&
    isNotEmpty(endDate) &&
    (dayjs(endDate).isSame(startDate) || dayjs(endDate).isAfter(startDate))
  );
};

export function SelectableDateRange({
  onRangeSelect,
  selectedRange,
  label,
}: Props) {
  const selectedRangeInitialState =
    selectedRange?.startDate && selectedRange?.endDate
      ? (selectedRange as DateRange)
      : undefined;

  const { endDate, setEndDate, startDate, setStartDate } = useDateValues(
    selectedRangeInitialState
  );

  const handleStartDateSelection = (value: string) => {
    if (dayjs(endDate).isBefore(value)) {
      setEndDate(undefined);
    }

    setStartDate(value);

    if (isEndDateValid(value, endDate)) {
      onRangeSelect({
        startDate: value,
        endDate: endDate as string,
      });
    }
  };

  const handleEndDateSelection = (value: string) => {
    setEndDate(value);

    if (startDate) {
      onRangeSelect({
        startDate,
        endDate: value,
      });
    }
  };

  const defaultDate = dayjs();

  return (
    <>
      <FilterHeader defaultMode={Mode.INCLUDES} />
      <When condition={label}>
        <Text margin="0.5rem" marginBottom="0">
          {label}
        </Text>
      </When>
      <FlexContainer>
        <StartInputWrap>
          <Input
            aria-label="Start date"
            type="date"
            value={startDate ?? ""}
            min={getDateToMinValue(defaultDate)}
            max={defaultDate.format(dateFormat.shortDate)}
            onChange={(event) => handleStartDateSelection(event.target.value)}
            small
          />
        </StartInputWrap>

        <EndInputWrap>
          <Input
            aria-label="End date"
            type="date"
            value={endDate ?? ""}
            min={startDate}
            max={defaultDate.format(dateFormat.shortDate)}
            disabled={startDate === undefined}
            onChange={(event) => handleEndDateSelection(event.target.value)}
            small
          />
        </EndInputWrap>
      </FlexContainer>
    </>
  );
}

export const StartInputWrap = styled.div`
  width: 100%;
  padding: 0.5rem;
  padding-right: 0.25rem;
`;

export const EndInputWrap = styled.div`
  width: 100%;
  padding: 0.5rem;
  padding-left: 0.25rem;
`;
