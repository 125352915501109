/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useReducer } from "react";
import dayjs from "dayjs";
import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import { Box, Button, FlexContainer, Sidebar, theme } from "@nordcloud/gnui";
import { Mode } from "~/components";
import { FilterWrapper } from "~/components/SidebarWithMultiSelect/FilterWrapper";
import { SelectableDateRange } from "~/components/SidebarWithMultiSelect/SelectableDateRange";
import { SelectableListWithSearch } from "~/components/SidebarWithMultiSelect/SelectableListWithSearch";
import { dateFormat } from "~/constants";

import { useDisclosure, useQueryState } from "~/hooks";
import { useGetAllActions } from "~/hooks/useGetAllActions";
import { isNotEmpty, isNotNil } from "~/tools";

import {
  QueryGetPlans,
  planStatusCheckboxes,
  scheduleOptionsCheckboxes,
} from "./constants";

type SidebarFooterProps = {
  onApply: () => void;
  onClear: () => void;
};

type State = {
  startDate?: string;
  endDate?: string;
  planStatuses: string[];
  excludePlanStatuses: boolean;
  planActionIds: string[];
  excludePlanActionIds: boolean;
  scheduleOptions: string[];
  excludeScheduleOptions: boolean;
};

function SidebarFooter({ onApply, onClear }: SidebarFooterProps) {
  return (
    <Col>
      <Row>
        <Box spacing="spacing02" css={{ marginBottom: 0 }}>
          <FlexContainer justifyContent="start" alignItems="center">
            <Button
              mr={theme.spacing.spacing04}
              severity="low"
              onClick={onApply}
            >
              Apply
            </Button>
            <Button
              mr={theme.spacing.spacing04}
              severity="low"
              onClick={onClear}
            >
              Clear
            </Button>
          </FlexContainer>
        </Box>
      </Row>
    </Col>
  );
}

export function PlanFiltersSidebar() {
  const { isOpen, open, close } = useDisclosure();
  const { queryState, updateQueryState } = useQueryState<QueryGetPlans>();

  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    {
      startDate: queryState.startDateUTC
        ? dayjs(queryState.startDateUTC).format(dateFormat.shortDate)
        : undefined,
      endDate: queryState.endDateUTC
        ? dayjs(queryState.endDateUTC).format(dateFormat.shortDate)
        : undefined,
      planStatuses: queryState.planStatuses,
      excludePlanStatuses: queryState.excludePlanStatuses,
      planActionIds: queryState.planActionIds,
      excludePlanActionIds: queryState.excludePlanActionIds,
      scheduleOptions: queryState.scheduleOptions,
      excludeScheduleOptions: queryState.excludeScheduleOptions,
    }
  );

  const applyFilters = () => {
    updateQueryState({
      ...queryState,
      startDateUTC: state.startDate
        ? dayjs(state.startDate).format(dateFormat.fullDate)
        : undefined,
      endDateUTC: state.endDate
        ? dayjs(state.endDate).endOf("day").format(dateFormat.fullDate)
        : undefined,
      planStatuses: state.planStatuses,
      excludePlanStatuses: state.excludePlanStatuses,
      planActionIds: state.planActionIds,
      excludePlanActionIds: state.excludePlanActionIds,
      scheduleOptions: state.scheduleOptions,
      excludeScheduleOptions: state.excludeScheduleOptions,
    });
    close();
  };

  const clearAllFilters = () => {
    updateState({
      startDate: undefined,
      endDate: undefined,
      planStatuses: [],
      excludePlanStatuses: false,
      planActionIds: [],
      excludePlanActionIds: false,
      scheduleOptions: [],
      excludeScheduleOptions: false,
    });
    updateQueryState({
      ...queryState,
      startDateUTC: undefined,
      endDateUTC: undefined,
      planStatuses: [],
      excludePlanStatuses: false,
      planActionIds: [],
      excludePlanActionIds: false,
      scheduleOptions: [],
      excludeScheduleOptions: false,
    });
    close();
  };

  const clearDateCreatedFilter = () => {
    updateState({ startDate: undefined, endDate: undefined });
  };

  const clearPlanStatusesFilter = () => {
    updateState({
      planStatuses: [],
      excludePlanStatuses: false,
    });
  };

  const clearPlanActionIdsFilter = () => {
    updateState({
      planActionIds: [],
      excludePlanActionIds: false,
    });
  };

  const clearScheduleOptionsFilter = () => {
    updateState({
      scheduleOptions: [],
      excludeScheduleOptions: false,
    });
  };

  const { customActions, systemActions } = useGetAllActions();

  const eventActionCheckboxes = [
    ...systemActions.map((action) => ({
      value: action.id,
      label: action.name,
    })),
    ...customActions.map((action) => ({
      value: action.id,
      label: action.name,
      subLabel: "Custom Action",
    })),
  ];

  return (
    <>
      <Button
        mr={theme.spacing.spacing04}
        severity="low"
        icon="filter"
        onClick={open}
      >
        Filters
      </Button>
      <When condition={isOpen}>
        <Sidebar
          title="Filter Results"
          isOpen={isOpen}
          onClick={close}
          footer={
            <SidebarFooter onApply={applyFilters} onClear={clearAllFilters} />
          }
        >
          <FilterWrapper
            name="Date Created"
            isSelected={!!state?.startDate && !!state?.endDate}
            selectedOptions={["startDate", "endDate"]}
            onClear={clearDateCreatedFilter}
          >
            <SelectableDateRange
              selectedRange={{
                startDate: state.startDate,
                endDate: state.endDate,
              }}
              onRangeSelect={(range) => updateState(range)}
              label="Time range when plan was created"
            />
          </FilterWrapper>
          <FilterWrapper
            name="Plan Status"
            isSelected={
              isNotNil(state.planStatuses) && isNotEmpty(state.planStatuses)
            }
            selectedOptions={state.planStatuses ?? []}
            onClear={clearPlanStatusesFilter}
          >
            <SelectableListWithSearch
              allowModeChange
              selectedOptions={state.planStatuses ?? []}
              selectableItems={planStatusCheckboxes}
              isExcludeSelected={state.excludePlanStatuses}
              onMultiSelect={(planStatuses) => updateState({ planStatuses })}
              onModeChange={(mode) =>
                updateState({ excludePlanStatuses: mode === Mode.EXCLUDES })
              }
            />
          </FilterWrapper>
          <FilterWrapper
            name="Plan Actions"
            isSelected={
              isNotNil(state.planActionIds) && isNotEmpty(state.planActionIds)
            }
            selectedOptions={state.planActionIds ?? []}
            onClear={clearPlanActionIdsFilter}
          >
            <SelectableListWithSearch
              allowModeChange
              selectedOptions={state.planActionIds ?? []}
              selectableItems={eventActionCheckboxes}
              isExcludeSelected={state.excludePlanActionIds}
              onMultiSelect={(actionIds) =>
                updateState({ planActionIds: actionIds })
              }
              onModeChange={(mode) =>
                updateState({ excludePlanActionIds: mode === Mode.EXCLUDES })
              }
            />
          </FilterWrapper>
          <FilterWrapper
            name="Schedule Options"
            isSelected={
              isNotNil(state.scheduleOptions) &&
              isNotEmpty(state.scheduleOptions)
            }
            selectedOptions={state.scheduleOptions ?? []}
            onClear={clearScheduleOptionsFilter}
          >
            <SelectableListWithSearch
              allowModeChange
              selectedOptions={state.scheduleOptions ?? []}
              selectableItems={scheduleOptionsCheckboxes}
              isExcludeSelected={state.excludeScheduleOptions}
              onMultiSelect={(scheduleOptions) =>
                updateState({ scheduleOptions })
              }
              onModeChange={(mode) =>
                updateState({
                  excludeScheduleOptions: mode === Mode.EXCLUDES,
                })
              }
            />
          </FilterWrapper>
        </Sidebar>
      </When>
    </>
  );
}
