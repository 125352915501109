/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  EventActionAttempt,
  ExecutionStatus,
  BatchActionFragment,
  EventActionFragment,
  EventAction,
  IncomingEventsListBatchActionFragment,
  IncomingEventsListPlanActionFragment,
  IncomingEventsListPlanActionBatchFragment,
  IncomingEventDetailPlanBatchActionFragment,
  ExecutionPolicy,
  SkipWindowType,
  EventDetailResourceGroupFragment,
  Maybe,
  EventActionBatchFragment,
  BatchAction,
} from "~/generated/graphql";
import { ToggleItem } from "~/components/ToggleItemBox";

export enum Period {
  week = "WEEK",
  month = "MONTH",
  year = "YEAR",
  range = "RANGE",
}
export enum EventType {
  pastCurrent = "PAST_CURRENT",
  future = "FUTURE",
}

export enum EventsFilterFields {
  startDate = "startDate",
  endDate = "endDate",
}

export type EventsFiltersType = {
  startDate?: string;
  endDate?: string;
};

export type Item = ToggleItem & {
  onSelect?: (value: EventActionAttempt) => void;
};

export enum EntityType {
  EVENT = "Event",
  FUTURE_EVENT = "FutureEvent",
  FUTURE_ACTION_BATCH = "FutureActionBatch",
  ACTION = "Action",
  ACTION_BATCH = "ActionBatch",
}

export type EventEntity = {
  __typename?:
    | EventActionFragment["__typename"]
    | EventActionBatchFragment["__typename"]
    | BatchAction["__typename"]
    | BatchActionFragment["__typename"];
  id: string;
  name: string;
  action?: EventActionFragment["action"];
  scope?: EventActionFragment["scope"];
  order?: EventActionFragment["order"];
  attempts?: EventActionAttempt[];
  executionPolicy?: EventActionFragment["executionPolicy"];
  windowDuration?: EventActionFragment["windowDuration"];
  skipWindow?: EventActionFragment["skipWindow"];
  status?: ExecutionStatus;
  runInSequence?: EventAction["runInSequence"];
  globalState?:
    | EventActionFragment["globalState"]
    | BatchActionFragment["globalState"];
  actions?: BatchActionFragment[];
  subRows?: BatchActionFragment[];
  resourceStates?:
    | BatchActionFragment["resourceStates"]
    | EventActionFragment["resourceStates"];
  resourceGroups?:
    | BatchActionFragment["resourceGroups"]
    | EventActionFragment["resourceGroups"];
  startTime?: EventActionBatchFragment["startTime"];
};

export type PlanEntityIncomingEventDetails = {
  id: string;
  name: string;
  order?: number;
  action?: IncomingEventDetailPlanBatchActionFragment["action"];
  batchActions?: (IncomingEventDetailPlanBatchActionFragment & {
    entityType: string;
  })[];
  resourceGroups?: Maybe<EventDetailResourceGroupFragment[]>;
  entityType: string;
  runInSequence?: EventAction["runInSequence"];
  executionPolicy?: ExecutionPolicy;
  skipWindow?: SkipWindowType;
  windowDuration?: number;
  inputParameters?: IncomingEventDetailPlanBatchActionFragment["inputParameters"];
};

export type EventActionItem = ToggleItem & {
  onSelect?: (value: EventActionAttempt) => void;
};

export type PlanEntityIncomingEventList = {
  id: string;
  name: string;
  order?: number;
  action?: IncomingEventsListPlanActionFragment["action"];
  batchActions?: IncomingEventsListPlanActionBatchFragment["batchActions"];
  subRows?: IncomingEventsListBatchActionFragment[];
  resourceGroups?:
    | IncomingEventsListPlanActionFragment["resourceGroups"]
    | IncomingEventsListBatchActionFragment["resourceGroups"];
};
